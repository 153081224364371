.p-password-panel {
  padding: 0.25em 0.5em;
  margin-top: 2px;
  background-color: black;
}

.p-password-panel .p-password-meter {
  height: 10px;
  background: transparent url('/images/password-meter.png') no-repeat left top;
  padding: 0;
  margin: 0;
}

.p-password-info {
  margin-top: 0.25em;
}

.p-password-panel-overlay {
  position: absolute;
}

.p-input-overlay-visible {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.p-input-overlay-hidden {
  opacity: 0;
  -webkit-transform: translateY(5%);
  -ms-transform: translateY(5%);
  transform: translateY(5%);
  -webkit-transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s;
}


.form-control__checkbox input[type=checkbox]:disabled:checked+label svg{
  background-color:rgb(119, 119, 128);
}

.form-control__checkbox input[type=checkbox]:disabled:not(:checked)+label svg{
  background-color: transparent;
}

/*.form-control__checkbox input[type=checkbox]:checked+label input[type=checkbox]:disabled+label svg{
   rgb(27, 27, 29)
  background-color: rgb(10, 65, 197);
    background-color: rgb(10, 65, 197);
}*/

/*.form-control__checkbox input[type=checkbox][checked][disabled]+label svg{
  background-color: rgb(27, 27, 29);
}*/

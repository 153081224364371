/*
**********************************
  -------------------------------
  Variables by @media screen size
  -------------------------------
**********************************
*/
@media only screen and (max-width: 47.9375rem) {
  :root {
    --media-max-width-small: 47.9375rem; /* 767px when var(--token-spacing-4) = 1rem */
    --content-max-width: 100%; /* 768px when var(--token-spacing-4) = 1rem */
    --menu-padding-left-right: var(--token-spacing-2);
    --menu-padding-top-bottom: 0;
    --blue-menu-padding-right: var(--token-spacing-2);
    --menus-height: 365px;
    --loginBackGroud: url(/images/loginPage2.png);
  }
}

@media only screen and (min-width: 48rem) {
  :root {
    --media-min-width-medium: 48rem; /* 768px when var(--token-spacing-4) = 1rem */
    --content-max-width: 100%; /* 768px when var(--token-spacing-4) = 1rem */
    --menu-padding-left-right: var(--token-spacing-3);
    --menu-padding-top-bottom: calc(var(--token-spacing-1) / 2);
    --blue-menu-padding-right: var(--token-spacing-3);
    --menus-height: 430px;
    --loginBackGroud: url(/images/loginPage1.png);
  }
}

@media only screen and (min-width: 64rem) {
  :root {
    --media-min-width-large: 64rem; /* 1024px when var(--token-spacing-4) = 1rem */
    --content-max-width: 64rem; /* 768px when var(--token-spacing-4) = 1rem */
    --menu-padding-left-right: var(--token-spacing-4);
    --menu-padding-top-bottom: var(--token-spacing-1);
    --blue-menu-padding-right: var(--token-spacing-8);
    --menus-height: 470px;
    --loginBackGroud: url(/images/loginPage0.png);
  }
}

:root {
  --token-color-outline: #61a0f7;
}

#__next {
  height: 96vh !important;
  overflow: auto;
}

/*
******************************
  ---------------------
  Top White Menu styles
  ---------------------
******************************
*/

.white-frame.top-menu {
  background-color: var(--token-background-primary) !important;
}

.white-frame.top-menu .middle {
  flex-direction: column;
  margin-top: var(--token-spacing-2);
  margin-bottom: var(--token-spacing-2);
  justify-content: left;
  width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  align-items: flex-end;
}
.left{
  padding-left: 0 em;

  /* flex-direction: column;
  margin-top: var(--token-spacing-2);
  margin-bottom: var(--token-spacing-2);
  justify-content: left;
  width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  align-items: flex-end; */
}

/*
******************************
  ---------------------
  Top Blue Menu styles
  ---------------------
******************************
*/
.blue-frame.top-menu {
  color: var(--token-text-color-inverse-primary);
  height: 66px;
}

.blue-frame.top-menu .middle {
  justify-content: center;
  width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--token-spacing-16);
  padding-right: var(--token-spacing-16);
}

.blue-frame.top-menu .fit {
  justify-content: center;   
}

/*
*******************************************
  ------------------
  Page Header styles
  ------------------
*******************************************
*/

.white-frame.pageTitle .middle {
  flex-direction: column;
  margin-top: var(--token-spacing-6);
  margin-left: auto;
  margin-right: auto;
  justify-content: left;
  width: var(--content-max-width);
  padding-left: var(--token-spacing-16) !important;
  padding-right: var(--token-spacing-16) !important;
}

.white-frame.pageTitle.left {
  padding-left: .1em;
}
/*
*******************************************
  ---------------------------
  Content area (pages) styles
  ---------------------------
*******************************************
*/
.ContentColumn {
  min-height: calc(100vh - var(--menus-height));
  margin-bottom: var(--token-spacing-6);
  width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--token-spacing-16) !important;
  padding-right: var(--token-spacing-16) !important;
  /* justify-content: auto !important; */
}
.ContentColumnLeftAlign {
  height: calc(100vh - var((0))) !important;
  margin-bottom: var(--token-spacing-6);
  width: 99%;
  margin-left: 0EM;
  margin-right: 0;
  padding-left: var(0EM) !important;
  padding-right: 0 !important;
}

.contentArea .usb-grid--container .grid .col {
  /* justify-content: flex-start; */
}
.contentAreaLeftAlign {
  /* justify-content: flex-start; */
  margin-left: .2EM;

}


nav > ul > li > a:active,
nav > ul > li > a:focus {
  outline: 2px solid var(--token-color-data-1);
}

/*
*******************************************
  -------------
  Footer styles
  -------------
*******************************************
*/

.blue-frame.footer {
  color: var(--token-text-color-inverse-primary);
}

.blue-frame.footer .middle {
  flex-direction: column;
  margin-top: var(--token-spacing-3);
  margin-bottom: var(--token-spacing-3);
  justify-content: left;
  width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.journeyLine {
  margin-bottom: 30px;
}

.journeyLineImage {
  width: 100%;
  height: auto;
}

.appversion {
  float:none;
  font-size: var(--token-font-size-sm);
  color: rgb(130, 132, 138);
  font-weight:100;
  margin: 1rem;

}
.appversion:hover, .appversion:focus
{
  font-size: var(--token-font-size-sm);
  color: white;
  font-weight: 600;
  margin: 1rem;

}

.usb-text_area {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.usb-text_area--wrapper .usb-text_area-basic {
  border:solid;
  border-width: thin;
  font-family: HelveticaNeue-USB, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  margin-top: 0;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  width: 100%;
}


footer .usb-list.horizontal {
  border: none !important;
}

footer .usb-list.horizontal li {
  /* border: none !important; */
  border-bottom: none;
  border-right: 2px solid #55639d;
  padding: 0;
}

footer .usb-list.horizontal li a {
  /* top&bottom right&left */
  padding: 0px var(--menu-padding-left-right);
}

footer .usb-list.horizontal li:last-of-type {
  border-right: none;
}

footer .secured {
  width: 18px;
  height: 18px;
  float: left;
  margin-top: 2px;
}

footer .copyright {
  margin-top: var(--menu-padding-left-right);
  padding-left: var(--menu-padding-left-right);
  width: 100%;
  justify-content: space-evenly;
}

footer .copyright .left {
  float: left;
  margin-top: -2px;
}

footer .copyright .right {
  float: right;
  padding-right: var(--menu-padding-left-right);
}

.timeout:hover, .timeout:focus, .timeoutshow {
  font-size: var(--token-font-size-sm);
  color: white;
  font-weight: 600;
  margin: 1rem;

}
.timeout{
  font-size: var(--token-font-size-sm);
  float: right;
vertical-align: top;
color: #0c2074;
  display: flex;
  font-weight:600;
  margin: 1rem;

}
/*
*******************************************
  ------------------
  Page Header styles
  ------------------
*******************************************
*/

.page-header {
  font-size: var(--token-font-size-2xl);
  font-weight: 600;
  color: var(--token-color-brand-primary);
  padding-bottom: var(--token-spacing-4);
}

.page-header.darkbg {
  color: white;
}

.page-header.center {
  text-align: center;
  width: 100%;
}

.field-label {
  font-size: 12px;
  color: var(--token-text-color-tertiary);
}

.field-label.focus {
  color: var(--token-border-focus) !important;
}

/*
*******************************************
  -----------------------------------
  PrimeReact components customization
  -----------------------------------
*******************************************
*/

.p-component {
  font-family: var(--token-font-base) !important;
  font-size: var(--token-font-size-base) !important;
}

.p-inputtext {
  font-family: var(--token-font-base) !important;
  font-size: var(--token-font-size-base) !important;
}

.p-highlight {
  background-color: var(--token-background-tertiary) !important;
  color: black !important;
}

/* .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0 !important;
  border: 2px solid var(--token-color-outline) !important;
  border-radius: 3px !important;
} */

.p-inputtext.p-component.custheight {
  width: 100% !important;
  height: 35px !important;
}

.p-inputtext {
  width: 100% !important;
  border: 0px !important;
  border-bottom: 1px solid var(--token-border-input) !important;
  /* border-radius: 0px !important; */
  margin-left: -3px;
}

/**
Prime React dropdown customization
*/

.p-dropdown.p-component {
  min-width: 150px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 3px !important;
}

.p-dropdown.p-component .autowidth {
  width: auto !important;
}

.p-dropdown.formcontrol {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid var(--token-border-input) !important;
  width: 100% !important;
}

.p-dropdown.formcontrol.error {
  border-bottom: 1px solid var(--token-text-color-error) !important;
}
.p-dropdown-panel {
  border: 1px solid var(--token-color-grey-30) !important;
}

.p-dropdown.formcontrol:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0 !important;
  border-radius: 3px !important;
  border: none !important;
  border: 2px solid var(--token-color-outline) !important;
}

.p-dropdown-panel .p-dropdown-filter-container {
  max-width: 200px;
  border-bottom: 0px !important;
}

.p-dropdown-filter {
  border-radius: 0px !important;
}

.p-dropdown label.p-dropdown-label {
  color: var(--token-text-color-secondary);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-top: 1px solid var(--token-color-grey-30) !important;
  white-space: nowrap;
}

.p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--token-background-secondary) !important;
  color: var(--token-text-color-interaction) !important;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  top: 67% !important;
  margin-top: -14px !important;
}

.p-dropdown-panel .p-dropdown-filter {
  height: 30px;
}

.p-dropdown-label .p-inputtext .p-placeholder {
  white-space: nowrap;
}

.p-dropdown-label.p-inputtext {
  border-bottom: 0px !important;
}

/**
Prime React accordion customization
*/
.p-accordion .p-accordion-tab {
  margin-bottom: 20px !important;
}

.p-accordion .p-accordion-tab .p-accordion-header:not(.p-disabled) a:focus {
  box-shadow: 0 0 0 0 var(--token-color-outline) !important;
  border: 2px solid var(--token-color-outline) !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) a {
  background-color: var(--token-background-secondary) !important;
  color: black;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  background-color: var(--token-background-tertiary) !important;
  border-color: rgb(200, 200, 200) !important;
  color: black;
}

.p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  color: black !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight a,
.p-accordion .p-accordion-header a .p-accordion-toggle-icon,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight a .p-accordion-toggle-icon {
  color: black !important;
}

/**
Currently not using chips control
Prime React chips componenet customization
*/

.p-chips .p-component {
  width: 100% !important;
}

.p-chips > ul.p-inputtext {
  width: 100% !important;
  border: 0px !important;
  border-bottom: 1px solid var(--token-border-input) !important;
  border-radius: 0px !important;
  min-height: 30px !important;
  margin-left: -3px;
}

.p-inputtext,
.p-chips-token-label {
  font-size: var(--token-font-size-base) !important;
}

.p-chips > ul > li {
  margin-top: -6px !important;
}

.p-chips > ul > li:first-of-type {
  margin-left: 0px !important;
}

.divMyLabel .p-inputtext:enabled:focus,
.p-chips > ul.p-inputtext:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0 !important;
  border-bottom: 0px !important;
  border: 2px solid var(--token-color-outline) !important;
  border-radius: 3px !important;
}

.p-chips > ul.p-inputtext .p-chips-input-token input {
  width: inherit !important;
}

.p-chips > ul.p-inputtext .p-chips-input-token input:focus {
  border: 0px !important;
}

.p-chips > ul.p-inputtext .p-chips-input-token {
  width: 100%;
}
.p-chips-input-token .p-inputtext .p-component .p-filled {
  width: 100% !important;
}

.p-chips > ul.p-inputtext:not(.p-disabled).p-error {
  border-bottom: 1px solid var(--token-text-color-error) !important;
}

.mylabel-textarea {
  border: 0px !important;
  width: 100% !important;
  color: var(--token-border-input) !important;
  margin-left: -10px !important;
}

.inputError {
  border-top: 1px solid var(--token-text-color-error) !important;
}

.dropdownError {
  border: 10px solid var(--token-text-color-error) !important;
}

/*
*******************************************
  ------------------------------------
  Currently not using
  Styles for Div simulating html table
  ------------------------------------
*******************************************
*/

.divTable {
  display: table;
}

.divTable .divTableRow {
  display: table-row;
}

.divTable .divTableHeading {
  display: table-header-group;
  background-color: #ddd;
  font-weight: bold;
}

.divTable .divTable .divTableBody {
  display: table-row-group;
}

.divTable .divTableFoot {
  display: table-footer-group;
}

.divTable .divTableRow .divTableCell,
.divTable .divTableRow .divTableHead {
  display: table-cell;
  padding: 3px 10px;
}

/*
*******************************************
  -----------------------------
  Currently not using
  Page Loading (spinner) styles
  -----------------------------
*******************************************
*/

.page-loading {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--token-background-primary);
  z-index: 1001;
  opacity: 0.8;
}

.page-loading div {
  color: var(--token-text-color-error);
  text-align: center;
  font-size: 30px;
  margin: 0 auto;
  background-image: url(/images/loading.gif);
  height: 340px;
  width: 340px;
}

.usb-page-loading {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--token-background-primary);
  z-index: 1001;
  opacity: 0.8;
}

.USBLoaderContainer {
  display: block;
  max-width: 120px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -70px;
}

/*
*******************************************
  ------------------------------------
  Currently not using
  Styles for MyLabel component
  ------------------------------------
*******************************************
*/

.custom-label-heading {
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 3px;
  color: var(--token-text-color-tertiary);
}

.custom-label-text {
  margin-top: 7px;
  border-bottom: 1px solid var(--token-border-input);
  width: 100%;
  line-height: 25px;
  background-color: var(--token-background-primary);
}

.custom-label-text.disabled {
  background-color: var(--token-background-quaternary);
}

/*
*******************************************
  -----------------------------------
  USBShield components customization
  -----------------------------------
*******************************************
*/

.contentArea .usb-grid--container .grid .col .padRight {
  padding-right: 30px;
}

.form-control__input.error .p-inputtext {
  border-bottom: 0px !important;
}

.form-control__input input:read-only {
  border-bottom: 1px solid var(--token-border-input) !important;
}

/* .form-control__input input:read-only:focus {
  border: 2px solid var(--token-border-input) !important;
} */

.form-control__input .is-value .is-focused .is-read-only > label {
  color: red !important;
}

/* .form-control__input input.is-value:focus,
.form-control__input input:focus {
  border: 2px solid var(--token-color-outline);
  margin: 0;
  outline: none !important;
  border-radius: 3px !important;
} */

.form_control__select .form_control__select__container {
  border-bottom: 0px !important;
}

.form_control__select .form_control__select__container select {
  height: 28px;
  border-bottom: 1px solid var(--token-border-input) !important;
  border-radius: 0px !important;
}

/*
  appies to usbinput, primereact input, prime dropdown input
*/
.input-border.is-focused input,
.input-border.p-inputwrapper-focus,
.input-border.show-hide button:focus,
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
  border-bottom: none !important;
  border: 2px solid var(--token-color-outline) !important;
  border-radius: 3px !important;
}

.input-border.is-focused.is-read-only input {
  border: 2px solid var(--token-border-input) !important;
}

/* .form_control__select .form_control__select__container select:focus {
  height: 28px;
  outline: none !important;
  border-bottom: none !important;
  border: 2px solid var(--token-color-outline) !important;
  border-radius: 3px !important;
} */

.form_control__select .form_control__select__container .usb-icon {
  margin-top: 4px !important;
  margin-right: 2px !important;
}

.usb-button.button--small.norightmargin {
  margin-right: 0px !important;
}

.usb-button.button--small.margintall {
  margin-right: 0px !important;
  margin-top: 24px ;
}

.usb-button.button--primary.noleftmargin {
  margin-left: 0px !important;
}

.usb-notification > .confirmation p > span,
.usb-notification > .error p > span,
.usb-notification > .warning p > span {
  word-break: break-word;
}

.umRoles,
.umRoles .usb-list {
  width: 100%;
}

.umRoles .form-control__radio .input__container input[type='radio'] {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.umRoles .form-control__radio .label__container,
.umRoles .form-control__radio .input__container {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.form-control__radio .label__text {
  margin-top: 5px !important;
  line-height: 1.25;
}

.umRoles .usb-list > li:nth-child(odd) {
  background-color: var(--token-color-grey-20) !important;
}

li.usb-list__item::marker {
  content: none;
}

.umRoles .usb-grid--container {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

/*
*******************************************
  -----------------------------------
  UserManager email column
  -----------------------------------
*******************************************
*/

.emailColumn {
  justify-content: space-evenly !important;
}

.divaddemail {
  display: flex;
}

.divaddemail .add {
  float: right;
}

.divaddemail .remove {
  float: left;
  padding-right: 10px;
}

/*
****************************************************************
  --------------------------------------------------------------
  Customizing default title attribute
  add data-title attribute to <a> or any other element applicable
  to apply the below styles
  --------------------------------------------------------------
****************************************************************
*/

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #0c2074;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  padding: 0.25rem 0.5rem;
  position: absolute;
  max-width: 250px;
  min-width: 120px;
  font-size: 0.75rem;
  line-height: 1.5;
  visibility: hidden;
  z-index: 1;
}

[data-title] {
  position: relative;
}

.passwordfeedback {
  z-index: 1000;
  /* position: absolute; */
}

.modal-header {
  padding-top: 10px !important;
}

.backgroundImage {
  /* background-attachment: fixed; */
  background-position: center center;
  background-size: cover;
}

.gridBottomMargin {
  margin-bottom: 35px;
}

.rightAlign {
  width: 100%;
  text-align-last: right;
}

th.p-sortable-column.p-highlight {
  background-color: #e0e0e0 !important; 
  color: #333333;
}

.p-datatable-header {
  background-color: #c8c8c8 !important; 

}

table.timingsshow:hover, table.timingsshow:focus, table.timeingsshow {
  display: flex;
  font-size: .5 em;
  color: #0c2074;
  

}
table.timingshide{
  display: none;
}
li {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
}

.usb-grid--container .grid .col {
  -webkit-justify-content: normal;
  justify-content: normal;
}
/*
*******************************************
  -----------------------------------
  PrimeReact Dropdown customization
  -----------------------------------
*******************************************
*/

.usermanager {
  width: 100%;
}

.divMain {
  height: 100%;
  line-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.divLeft {
  margin-top: 25%;
  float: left;
  width: 50%;
  height: 100%vh;
  text-align: center;
  vertical-align: middle;
  margin-left: var(--token-spacing-16);
}

.divRight {
  float: right;
  width: 50%;
  height: inherit;
  justify-content: center;
  text-align: center;
  margin-left: 5px;
}

.usb-grid--container .grid .col.ContentColumn.Home {
  justify-content: space-evenly;
}

@media only screen and (max-width: 47.9375rem) {
  .Home .usb-grid--container {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media only screen and (min-width: 48rem) {
  .Home .usb-grid--container {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media only screen and (min-width: 64rem) {
  .Home .usb-grid--container {
    margin-left: var(--token-spacing-16);
    margin-right: var(--token-spacing-16);
    width: calc(var(--content-max-width) - 8rem);
  }
}

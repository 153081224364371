.login {
  margin-top: 25px;
}

.login .PageGrid {
  margin: 10px;
  color: white;
}

.login .PageGrid .table {
  margin: 10px;
  border-radius: 10px;
  background-color: var(--token-color-brand-primary);
}

.login .PageGrid .column {
  margin: 8px;
}

.input-darkbg.is-value label,
.input-darkbg.is-value .helper-text__text,
.input-darkbg.is-focused label,
.input-darkbg.is-focused .helper-text__text {
  color: var(--token-text-color-inverse-primary) !important;
}

.input-darkbg.error p {
  font-size: 14px !important;
}

.form-control__input.show-hide button {
  margin: 0;
  position: absolute;
  right: .5rem;
  top: .5rem;
  color: #6e6e6e;
  font-size: .75rem;
  font-weight: 400;
  border: none;
  padding: 1rem .5rem
}

.form-control__input input {
  color: #2c2c2c;
  font-family: HelveticaNeue-USB, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  outline: none;
  border: 0;
  border-bottom: 1px solid #858585;
  margin-bottom: 1px;
  width: 95%;
  padding-bottom: .25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all .3s ease
}

.usb-notification>.confirmation, .usb-notification>.error, .usb-notification>.information, .usb-notification>.warning {
  display: flex;
  align-content: center;
  vertical-align: middle;
  width: 95%;
  padding: .75rem 3rem 1rem 1rem;
  border-radius: .5rem;
  position: relative;
  outline-offset: -1px;
  color: #2c2c2c;
  font-family: HelveticaNeue-USB, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5
}
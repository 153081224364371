/*
**************************************************************************
  ---------------------------------------------------------------------
  Shield's USBList component styles customization
  Padding: top right bottom left
  Padding: 25px 50px = top and bottom are 25px, right and left are 50px
  ---------------------------------------------------------------------
**************************************************************************
*/

.usb-list.horizontal {
  list-style: none;
  display: table;
}

.usb-list.horizontal li {
  display: table-cell;
  vertical-align: middle;
}

.usb-list.horizontal li a {
  /* top&bottom right&left */
  padding: var(--menu-padding-top-bottom) var(--menu-padding-left-right);
}

/*
*******************************************
  ---------------------
  Common styles for top White and Blue menu
  ---------------------
*******************************************
*/

a.basic {
  font-weight: 400;
}

a.subtle:hover {
  text-decoration: underline;
}

a.subtle:any-link {
  font-size: inherit;
  text-decoration: none;
  margin-right: auto;
  font-weight: 500 !important;
}

/*
*******************************************
  ---------------------
  Top White Menu styles
  ---------------------
*******************************************
*/

a.subtle.whitebar-menuitem,
a.subtle.whitebar-menuitem:any-link,
a.subtle.whitebar-menuitem:hover {
  color: var(--token-text-color-tertiary);
}

a.subtle.whitebar-menuitem:hover {
  text-shadow: 1px 0px 0px var(--token-text-color-tertiary);
}

.usb-list__item .welcome {
  color: var(--token-text-color-tertiary);
  padding-right: var(--menu-padding-left-right);
}

.welcome .tooltip__content--light {
  background-color: var(--token-color-grey-30) !important;
}

.welcome .tooltip__content {
  color: var(--token-text-color-primary) !important;
  z-index: 1001 !important;
}

.welcome .usb-icon.svg-icon.light {
  fill: var(--token-text-color-tertiary) !important;
}

.welcome .username {
  float: left;
  padding-right: 3px;
  margin-top: 3px;
  font-weight: 400;
}

.welcome .info {
  float: right;
  white-space: nowrap;
  margin-top: 3px;
}

/*
*******************************************
  --------------------
  Top Blue Menu styles
  --------------------
*******************************************
*/

a.subtle.bluebar-menuitem,
a.subtle.bluebar-menuitem:any-link,
a.subtle.bluebar-menuitem:hover {
  color: var(--token-text-color-inverse-primary);
}

a.subtle.bluebar-menuitem:hover {
  text-shadow: 1px 0px 0px var(--token-text-color-inverse-primary);
}

#topBlueNav > ul > li.usb-list__item {
  padding-right: var(--blue-menu-padding-right);
}
